





























































































































































































































































































































































































































































































.a2p-page {
  .multiselect--disabled {
    background-color: #d0d0d0 !important;

    .multiselect__tags,
    .multiselect__single,
    .multiselect__select {
      background-color: #d0d0d0 !important;
    }
  }
}
